#history-detail-dialog {
  header {
    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }
  .history-detail {
    margin-bottom: 2rem;

    &__item {
      display: flex;
      border: 1px solid #ccc;

      &:not(:nth-last-child(1)) {
        border-bottom: 0;
      }
      > *:nth-child(1) {
        flex: 1.5;
        border-right: 1px solid #ccc;
      }
      > *:nth-child(2) {
        flex: 4;
      }
      > * {
        padding: 1rem;
      }
    }

    .item {
      &__ttl {
        text-shadow: 0 0 #3f3f3f;

        h2 {
          line-height: 1.2;
        }
      }
      &__dt {
        max-height: 150px;
        overflow: auto;

        > * {
          color: #3f3f3f;
          line-height: 1.2;
        }

        p {
          word-break: break-word;
          white-space: break-spaces;
        }

        ul > li {
          color: #3f3f3f;
          word-break: break-word;
          white-space: break-spaces;
        }
        ul > li:not(:nth-last-child(1)) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #history-detail-dialog {
    .history-detail-wrapper {
      overflow: auto;
    }
    .history-detail {
      min-width: 500px;
      &__item > *:nth-child(1) {
        flex: 1;
      }
    }
  }
}
