.user-booking-time {
  &__wrapper {
    width: 60%;
    margin: auto;
  }

  * {
    font-size: 14px;
  }

  &__calendar {
    margin: 0 auto 2rem;
  }

  &__ctn-wrapper {
    max-width: 1000px;
    background-color: #fff;
    padding: 2rem 0 4rem;
  }

  &__block-times {
    padding: 0 5rem;

    .selected-date {
      margin-bottom: 10px;
      padding: 10px 10px;
      background: #f7f7f7;
      font-size: 16px;
      font-weight: bold;
    }

    ul {
      li {
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 6px;
        margin-bottom: 6px;
      }

      li.selected {
        border: 2px solid #49abbd;
      }
    }
  }

  &__hint {
    display: flex;
    margin-bottom: 10px;

    > *:not(:nth-last-child(1)) {
      margin-right: 20px;
    }

    div {
      display: flex;
      align-items: center;

      span {
        font-size: 30px;
      }
    }

    div:nth-child(1) {
      margin-left: 5rem;

      span {
        color: #ff93a9;
      }
    }

    div:nth-child(2) {
      span {
        color: #49abbd;
      }
    }
  }

  .multi-calendar__date {
    &.regular-holiday {
      &--saturday {
        color: #0097df;
      }

      &--sunday {
        color: #ff497e;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .user-booking-time {
    &__wrapper {
      width: 60%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .user-booking-time {
    &__wrapper {
      width: 70%;
    }
  }
  .multi-calendar.user-booking-time__calendar {
    > *:not(:nth-last-child(1)) {
      margin-bottom: 2rem;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .multi-calendar.user-booking-time__calendar {
    flex-direction: column;
    margin-bottom: 2rem;

    > *:not(:nth-last-child(1)) {
      margin-bottom: 3rem;
      margin-right: 0;
    }
  }
}
@media only screen and (max-width: 900px) {
  .user-booking-time {
    &__wrapper {
      width: 80%;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .user-booking-time {
    &__block-times {
      padding: 0 10%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .user-booking-time {
    &__calendar {
      flex-direction: column;
      margin-bottom: 1rem;

      .left-calendar,
      .right-calendar {
        display: flex;
        justify-content: center;
      }
    }

    &__hint {
      > *:not(:nth-last-child(1)) {
        margin-right: 5px;
      }
      div:nth-child(1) {
        margin-left: 10%;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .multi-calendar.user-booking-time__calendar {
    .multi-calendar__date,
    .multi-calendar__day {
      width: 40px;
      height: 35px;
      min-width: 40px;
      min-height: 35px;
      font-size: 14px;
    }

    .multi-calendar__days-row,
    .multi-calendar__week {
      > *:not(:nth-last-child(1)) {
        margin-right: 3px;
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .user-booking-time__wrapper {
    width: 100%;
  }
  .user-booking-time__ttl {
    padding: 0 20px;
  }

  .multi-calendar.user-booking-time__calendar {
    .multi-calendar__date,
    .multi-calendar__day {
      // width: 30px;
      // height: 35px;
      // min-width: 30px;
      // min-height: 30px;
      // font-size: 12px;
    }
  }
}

@media only screen and (max-width: 281px) {
  .user-booking-time__ctn-wrapper {
    padding: 2rem 10px 4rem;
  }
  .multi-calendar.user-booking-time__calendar {
    width: 100%;
    overflow: auto;
  }
}
