.register-success-dialog {
  display: flex;
  align-items: center;

  .dialog {
    width: 50%;
    max-width: 300px;
    margin: 0;
    padding: 1.5rem;
    min-width: 250px;
  }

  &__text {
    text-align: center;
    margin-bottom: 1.2rem;
  }

  &__button {
    border: 0;
    background: #009b00;
    padding: 10px 15px;
    color: white;

    &:hover {
      background: #00af00;
    }
  }
}
