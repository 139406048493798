.staff-dialog {
  figure.image-preview {
    margin-top: 1rem;
    width: 250px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .booking-form {
    &__label {
      flex: 2;
    }
  }

  .menu-container {
    padding: 10px 12px;
  }

  &__check-all-button {
    border: 1px solid #ccc;
    padding: 6px 10px;
    border-radius: 3px;
    background-color: #667dff;
    color: #fff;
    display: block;
    margin-bottom: 10px;

    &--check-all {
      background-color: #fff;
      color: #667dff;
    }
  }

  .control-working-hour {
    &__block-selector-wrapper {
      width: fit-content;

      &:hover .control-working-hour__block-selector {
        display: flex;
      }
    }

    &__block-selector {
      display: none;
      position: absolute;
      z-index: 999;
      width: max-content;
      flex-direction: column;
      background-color: #fff;
      top: 0;
      left: 16px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

      > li {
        padding: 10px;
        font-size: 14px;
        cursor: pointer;

        &:not(:nth-last-child(1)) {
          border-bottom: 1px solid #eee;
        }

        &:hover {
          background-color: rgba($color: #000, $alpha: 0.1);
        }
      }
    }

    &__select-wrapper {
      display: flex;
      margin-bottom: 10px;

      > div:not(:nth-last-child(1)) {
        margin-right: 15px;

        > label,
        > input[type="checkbox"] {
          cursor: pointer;
        }
        > input[type="checkbox"] {
          margin-right: 6px;
        }
      }

      span {
        margin-right: 10px;
      }
    }

    &__remove-btn {
      width: 18px;
      cursor: pointer;
      margin-left: 5px;
    }

    &__select {
      position: relative;
      margin-bottom: 10px;

      // select:not(:nth-last-child(1)) {
      //   margin-right: 10px;
      // }

      > div:not(:nth-last-child(1)) {
        margin-bottom: 10px;
      }

      svg {
        width: 16px;
        cursor: pointer;
        left: 0;
        bottom: -15px;
        fill: #4b4b4b;
        outline: none;

        &:hover {
          fill: #666666;
        }
      }
    }
  }
}
