.redirect {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-wrapper {
    margin: 0;
    margin-top: -18rem;

    .loader {
      font-size: 10px;
    }
  }
}
