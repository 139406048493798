.create-booking-alert {
  .dialog {
    width: 400px;
    min-width: 400px;
    margin: auto;

    header {
      color: #e22739;
    }
  }

  &__content {
    margin-bottom: 1rem;

    > * {
      margin-bottom: 10px;
    }

    p {
      line-height: 1.2;
      text-align: center;
    }
  }

  &__working-time {
    text-align: center;

    li {
      line-height: 1.6;
    }
  }

  &__alert-btn {
    background-color: #e22739 !important;

    &:hover {
      background-color: #da3b3b !important;
    }
  }

  &__btns {
    text-align: center;
  }
}
