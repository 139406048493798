.booking-success {
  background: #f7f8f8;

  * {
    font-size: 14px;
  }

  &__wrapper {
    max-width: 60%;
    margin: auto;
    padding: 23px 0;
  }

  &__username {
    margin-bottom: 4rem;

    * {
      font-size: 17px;
    }

    strong {
      margin-right: 5px;
    }
  }

  &__buttons {
    text-align: center;

    button {
      border: 0;
      outline: none;
      color: #fc3c60;
      background: none;
      text-shadow: 0 0 #fc3c60;
    }
  }

  &__content {
    width: 50%;
    margin: auto;
    margin-bottom: 60px;

    .booking-content {
      &__ttl {
        margin-bottom: 28px;

        > pre {
          overflow: hidden;
          line-height: 1.2;
          text-align: center;
        }

        h2 {
          font-size: 17px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 25px;
        }

        p {
          text-align: center;
        }
      }

      &__row {
        margin-bottom: 27px;
        display: flex;

        > *:not(:nth-last-child(1)) {
          margin-right: 30px;
        }
      }

      &__row.menu {
        .booking-content__label {
          align-self: flex-start;
        }
      }

      &__content {
        // padding-top: 2px;
        display: flex;
        align-items: center;
        ul li {
          line-height: 1.4;
          word-break: break-word;
          white-space: break-spaces;
        }

        ul {
          li:not(:last-child) {
            margin-bottom: 6px;
          }
        }
      }

      &__label {
        display: flex;
        align-items: center;
        min-width: 160px;
        width: 160px;
        font-weight: 500;

        > *:not(:nth-last-child(1)) {
          margin-right: 10px;
        }

        span {
          text-shadow: 0 0 black;
        }

        > svg {
          min-width: 20px;
        }

        span.estimate-amount {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .booking-success {
    &__wrapper {
      max-width: 60%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .booking-success {
    &__wrapper {
      max-width: 70%;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .booking-success {
    &__content {
      max-width: 70%;
      width: 70%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .booking-success {
    &__wrapper {
      max-width: 70%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .booking-success {
    &__content {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .booking-success {
    &__wrapper {
      max-width: 80%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .booking-success__content .booking-content__row > *:not(:nth-last-child(1)) {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .booking-success {
    &__wrapper {
      max-width: 88%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .booking-success {
    &__wrapper {
      max-width: 90%;
    }
  }

  .booking-success__content
    .booking-content__label
    > *:not(:nth-last-child(1)) {
    margin-right: 5px;
  }
}
