.common-return-top-button {
  margin-left: auto;
  display: block;
  padding: 6px 8px;
  background: #fff;
  border: 1px solid #b5b5b6;
  color: #9fa0a0;
  border-radius: 2px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #595757;

  &.large {
    max-width: 250px;
    width: 250px;
    margin: auto;
    text-align: center;
    padding: 8px;
  }
}
