// @import url('https://fonts.googleapis.com/css?family=Shippori+Mincho+B1');
// @import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(./../assets/font/MaterialIcons-Regular.ttf) format("truetype");
}
// @font-face {
//   font-family: "Yu Gothic Medium";
//   font-style: normal;
//   font-weight: 500;
//   src: local("Yu Gothic Medium"), local("Yu Gothic Medium"),
//     url(./../assets/font/Yu-Gothic-Medium.otf) format("opentype");
// }
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
}

@import "bootstrap/dist/css/bootstrap.min.css";
@import "./variables";
@import "./reset";
@import "./mixins";
@import "./common";
@import "./animation";
