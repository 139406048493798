.menu-dialog {
  .dialog {
    padding-bottom: 2rem;

    @media only screen and (max-width: 1400px) {
      min-width: 60%;
      width: 60%;
    }
    @media only screen and (max-width: 1000px) {
      min-width: 75%;
      width: 75%;
    }
    @media only screen and (max-width: 800px) {
      min-width: 95%;
      width: 95%;
    }
    @media only screen and (max-width: 600px) {
      header {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }

    @media only screen and (max-width: 500px) {
      .close-button {
        font-size: 1.6rem;
      }
    }
  }

  .booking-form__btns {
    justify-content: center;
  }

  figure.image-preview {
    margin-top: 1rem;
    width: 250px;
    height: 200px;
    position: relative;

    span {
      position: absolute;
      right: -30px;
      font-size: 1.8rem;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media only screen and(max-width: 360px) {
      width: 200px;
      height: 180px;
    }
  }

  .booking-form {
    &__row {
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 2rem;
      }

      &:nth-last-of-type(2) {
        margin-bottom: 3rem;
      }

      @media only screen and (max-width: 600px) {
        flex-direction: column;

        .booking-form__label {
          margin-bottom: 15px;
        }

        > *:nth-child(2) {
          margin-left: 1rem;
          @media only screen and (max-width: 360px) {
            margin-left: 0.3rem;
          }
        }

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 3rem;
        }

        &:nth-last-of-type(2) {
          margin-bottom: 3rem;
        }
      }
    }
    &__label {
      min-width: 200px;
    }
    &__control {
      input.price-input {
        width: 50%;
      }

      select.select-discount {
        width: 80%;
      }

      & .form-time {
        display: flex;

        input {
          width: 50%;
        }

        select {
          width: 86px;
        }

        select:first-child {
          margin-right: 10px;
        }
      }
      @media only screen and (max-width: 600px) {
        width: 90%;
        input.price-input,
        select.select-discount {
          width: 100%;
        }
      }
    }

    &__control .control-price {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }

    &__control.form-image {
      .radio-wrapper {
        font-size: 0.9rem;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 360px) {
          label:first-of-type {
            margin-right: 1rem !important;
          }
        }
      }

      .btn-wrapper {
        @media only screen and (max-width: 360px) {
          label {
            width: 180px;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
