.term-of-service {
  padding: 20px;

  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 1.4;
    font-size: 14px;
    overflow: hidden;
  }
}
