.dialog-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);

  .dialog {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 3rem;
    height: fit-content;
    min-width: 700px;
    width: 700px;
    padding: 1rem 2rem;
    background: #f5f5f5;
    border-radius: 5px;

    header {
      background: unset;
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 2rem;
      text-shadow: 0 0 #3e3e3e;
    }

    .close-button {
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 2rem;
      transition: all 0.1s ease;
      user-select: none;

      &:hover {
        cursor: pointer;
        color: grey;
      }
    }
  }
}

.show-dialog {
  display: flex;
}

.hide-dialog {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .dialog-overlay {
    .dialog {
      min-width: 80%;
      width: 80%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .dialog-overlay {
    .dialog {
      min-width: 86%;
      width: 86%;
    }
  }
}
