.light-calendar {
  // position: absolute;
  // bottom: 0;
  background-color: #344f4f;
  width: 100%;
  border-radius: 5px;

  &__header {
    display: flex;
    padding: 10px;
    align-items: center;

    p {
      text-align: center;
      color: #fff;
      line-height: 1;
    }
  }

  &__days-row {
    border-top: 1px solid #fff;
  }

  &__week {
    border-top: 1px solid #fff;
  }

  &__day {
    display: inline-block;
    text-align: center;
    width: calc(100% / 7);
    line-height: 1.5;
    font-size: 14px;
  }

  &__navigate {
    margin-left: auto;

    svg {
      height: 18px;
      fill: #fff;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__date {
    width: calc(100% / 7);
    border: 0;
    background: unset;
    line-height: 2.5;
    color: #fff;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:not(:nth-last-child(1)) {
      border-right: 1px solid #fff;
    }

    &--chosen-date {
      background: #fff;
      color: #000;
    }

    &--not-current-month {
      color: rgba($color: #fff, $alpha: 0.5);
      cursor: not-allowed;
    }
  }
}
