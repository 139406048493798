.user-menus {
  margin-top: 3rem;
  padding-bottom: 3rem;

  &__wrapper {
    margin: auto;
    width: 50%;
  }

  * {
    font-size: 14px;
    color: #595757;
  }

  &__ttl {
    section {
      display: flex;
      align-items: center;

      > *:not(:nth-last-child(1)) {
        margin-right: 10px;
      }
    }
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.4;
    }
  }

  &__ctn-wrapper {
    display: flex;
    background-color: #f7f8f8;

    > *:not(:nth-last-child(1)) {
      margin-right: 20px;
    }
  }

  &__list-menu {
    flex: 2.5;
    padding-bottom: 3rem;

    li {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    li:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__btn-return-top {
    margin-left: auto;
    padding: 8px;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #b5b5b6;
    cursor: pointer;
  }

  &__cart-and-charge {
    min-width: 250px;
    width: 250px;

    > div {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    flex: 1;
  }

  &__menu-info {
    padding: 23px 25px;
    background-color: #fff;

    > div:first-child {
      display: flex;
    }

    > div:first-child > *:not(:nth-last-child(1)) {
      margin-right: 20px;
    }
  }
  &__menu-info--selected {
    box-shadow: 0 -1px 0 0 inset #fc3c60, 0 1px 0 0 inset #fc3c60,
      1px 0 0 0 inset #fc3c60, 0 0 0 1px inset #fc3c60 !important;
  }

  &__menu-content {
    max-width: 400px;
  }

  &__menu-image {
    width: 82px;
    min-width: 82px;
    height: 109px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__menu-ttl {
    margin-bottom: 12px;
    font-weight: bold;
    color: #fc3c60;
    font-size: 16px;
    word-break: break-word;
    white-space: break-spaces;
  }

  &__menu-desc {
    font-size: 12px;
    color: #3e3a39;
    line-height: 1.2;
    white-space: break-spaces;
    line-break: anywhere;
  }

  &__time-and-price {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__chose-btn {
    border: 1px solid #fc3c60;
    font-size: 12px;
    padding: 8px 18px;
    color: #fc3c60;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    margin-top: auto;
    white-space: nowrap;
  }

  &__time {
    display: flex;
    margin-bottom: 16px;

    > *:not(:nth-last-child(1)) {
      margin-right: 5px;
    }

    span {
      font-size: 14px;
    }

    p {
      font-weight: bold;
    }
  }

  &__price {
    margin-bottom: 14px;
    font-size: 15px;
  }

  &__total-estimate {
    padding: 23px 25px;
    padding-bottom: 80px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-bottom: 0;
  }

  &__time-estimate,
  &__price-estimate,
  &__discount-estimate,
  &__money-estimate {
    display: flex;
    font-weight: bold;

    span:last-child {
      margin-left: auto;
    }
  }

  &__time-estimate,
  &__price-estimate {
    margin-bottom: 17px;
  }

  &__price-estimate,
  &__discount-estimate {
    span:nth-child(2) {
      color: #fd666c;
    }
  }

  &__money-estimate {
    padding: 18px 23px;
    border: 1px solid #e4e4e4;
    border-top: 0;
    margin-bottom: 24px;

    span:nth-child(2) {
      font-size: 16px;
      color: #fd666c;
    }
  }

  &__confirm-btn {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fc5461;
    border-radius: 2px;
    color: #fff !important;
    margin-bottom: 27px;
    border: 0;

    &:disabled {
      background: #fff;
      color: #9fa0a0 !important;
      border: 1px solid #b5b5b6;
    }
  }

  &__discount-info {
    margin-top: 15px;

    p {
      color: #fc3d61;
      line-height: 1.2;
      font-size: 12px;
    }
  }

  .chosen-menus {
    li {
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: #fff;
      padding: 10px 22px;
      padding-left: 42px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .chosen-menus__name {
        margin-bottom: 10px;
      }
    }

    li:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }

    &__close-btn {
      position: absolute;
      font-size: 14px;
      left: 10px;
      padding: 5px;
      border-radius: 50%;
      background: #f7f7f7;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      cursor: pointer;
    }

    &__price,
    &__name {
      text-align: right;
      font-weight: bold;
    }

    &__name {
      line-height: 1.5;
      color: #f99cae;
    }

    &__price {
      color: #fd666c;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .user-menus {
    &__wrapper {
      width: 60%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .user-menus {
    &__wrapper {
      width: 70%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .user-menus {
    &__wrapper {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .user-menus {
    &__ctn-wrapper {
      flex-direction: column-reverse;

      > *:not(:nth-last-child(1)) {
        margin-right: 0;
      }
    }

    &__confirm-btn {
      margin-bottom: 0;
    }

    &__cart-and-charge {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: unset;
      min-width: unset;

      > *:not(:nth-last-child(1)) {
        margin-bottom: 20px;
      }

      section,
      ul {
        min-width: 60%;
      }

      ul {
        overflow-y: auto;
        max-height: 250px;
        margin-bottom: 1rem;
      }
    }

    &__total-estimate {
      padding-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .user-menus {
    &__wrapper {
      width: 90%;
    }
    &__ttl {
      > *:not(:nth-last-child(1)) {
        margin-right: 10px;
      }
    }

    &__cart-and-charge {
      section,
      ul {
        min-width: 80%;
      }
    }

    &__menu-info {
      > div:first-child {
        flex-direction: column;
      }

      > div:first-child > *:not(:nth-last-child(1)) {
        margin-right: 0;
        margin: auto;
        margin-bottom: 15px;
      }
    }

    &__menu-image {
      margin: auto;
      width: 109px;
    }

    &__time {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .user-menus {
    &__ttl {
      flex-direction: column-reverse;
    }
  }
}
