.primary-button {
  &.MuiButton-root {
    padding: 10px 40px;
    font-weight: 500;
    white-space: nowrap;
  }

  &--search {
    background-color: #1996d2 !important;
  }
  &--detail {
    background-color: #009d95 !important;
  }

  &--create {
    background-color: #667dff !important;
    transition: background 0.5s ease-in-out;

    &:hover {
      background-color: #4661ff !important;
    }
  }

  &--edit {
    background-color: #4894e5 !important;
    transition: background 0.5s ease-in-out;

    &:hover {
      background-color: #2d88e8 !important;
    }
  }

  &--delete {
    background-color: #ff7e7e !important;
    transition: background 0.5s ease-in-out;

    &.primary-button--is-loading.MuiButton-root {
      padding: 10px 20px !important;
    }

    &:hover {
      background-color: #ff5c5c !important;
    }
  }

  &--edit,
  &--delete,
  &--search,
  &--detail {
    &.primary-button.MuiButton-root {
      padding: 0.6rem 1rem;
    }
  }

  &--add {
    background-color: #1996d2 !important;
    transition: background 0.5s ease-in-out;

    &.primary-button.MuiButton-root {
      padding: 10px 50px;
    }

    &:hover {
      background-color: #1b8bc1 !important;
    }
  }

  &--cancel {
    &.primary-button.MuiButton-root {
      padding: 10px 20px;
    }

    &.MuiButton-outlinedPrimary {
      border: 1px solid #dfdfdf;
      color: #818181;
      background-image: linear-gradient(0, #fff, #fff);

      &:hover {
        border: 1px solid #dfdfdf;
        background-image: linear-gradient(0, #efefef, #fff);
      }
    }
  }

  &--is-loading.MuiButton-contained.Mui-disabled {
    color: #fff;
    opacity: 0.6;
    cursor: not-allowed;

    &.primary-button.MuiButton-root {
      padding: 10px 33px;
    }

    &:before {
      content: "";
      background-image: url("/assets/icons/outline_loop_black.png");
      background-size: 100% 100%;
      filter: invert(100%);
      height: 20px;
      width: 30px;
      background-repeat: no-repeat;
      animation: spin 1s infinite linear;
      margin-right: 3px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
