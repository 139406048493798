.history {
  width: 100%;

  &__search {
    // display: flex;
    // align-items: center;
    button {
      vertical-align: top;
    }
  }

  &__table {
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 1rem;

    table > thead > tr {
      white-space: nowrap;
      background: #5d6066;
      color: #fff;

      th {
        vertical-align: middle;
        text-align: center;
        line-height: 1.2;
        vertical-align: middle;
        font-weight: normal;
        border: 1px solid grey;
      }
      th.col-username {
        width: 10%;
      }
      th.col-service {
        width: 20%;
      }
      th.col-status {
        width: 10%;
      }
    }
    table > tbody > tr {
      cursor: pointer;
      transition: all 0.1s ease;
      background: #fffafa;
      transition: background 0.1s ease;

      > td {
        border: 1px solid rgb(196, 196, 196);
        text-align: center;
        vertical-align: middle;
        line-height: 1.2;
        color: #3e3e3e;
      }
      &:hover {
        background: #e7e7e7;
      }
    }
  }
}

.my-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  > li:not(:nth-last-child(1)) {
    margin-right: 10px;
  }

  > li {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    color: #6a6a6a;

    > a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }

  li.previous,
  li.next {
    > a > span {
      font-size: 1.5rem;
    }
  }

  > li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  > li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  li.active {
    background-color: #616161;
    color: #fff;
  }

  li.navigation-disabled {
    opacity: 0.6;

    a {
      cursor: not-allowed;
    }
  }
}

#history-delete-dialog {
  > .dialog {
    width: auto;
    margin-top: -30vh;

    main {
      > a:first-child {
        margin-right: 1rem;
      }
    }
  }
}

.loader,
.loader:before,
.loader:after {
  background: #adadad;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #adadad;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 7px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.loader-wrapper {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 6rem;
}

@media only screen and (max-width: 770px) {
  .history__table {
    overflow-x: auto;

    table {
      width: 770px;
    }
  }
}
@media only screen and (max-width: 310px) {
  .history__search {
    button {
      width: fit-content;
    }
  }
}
