html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
main,
video {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  border: 0;
  font-size: 15px;
  // font-family: "Shippori Mincho B1";
  // font-family: "Noto Serif JP", serif;
  // font-family: "Kosugi Maru", sans-serif;
  // font-family: "Kosugi Maru", sans-serif;
  // font-family: "游ゴシック Light";
  // font-family: "游明朝 Regular";
  // font-family: "游明朝";
  // font-family: "游ゴシック体 ボールド";
  // font-family: "游明朝 Regular";
  // font-family: "游明朝 Demibold";
  // font-family: "'游明朝体";
  // font-family: "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo, sans-serif;
  // font-family: "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  // font-family: "Sazanami Mincho";
  // font-family: Hiragino Kaku Gothic ProN,
  //   "\30D2\30E9\30AE\30CE\89D2\30B4 ProN W3", Meiryo, メイリオ, Osaka,
  //   MS PGothic, arial, helvetica, sans-serif;
  // font-family: "Yu Gothic Medium";
  // font-family: "Noto Sans JP", "メイリオ", Arial, sans-serif;
  font-family: "Noto Sans JP", sans-serif;
  vertical-align: baseline;
  line-height: 1;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  height: 100%;
}

body {
  line-height: 1;
  min-height: 100%;
  height: 100%;

  &.modal-open {
    position: fixed;
    width: 100%;
  }
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
header,
a {
  color: #3e3e3e;
  letter-spacing: 1px;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

main {
  max-width: unset;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: $black;

  &:hover {
    color: $black;
  }
}

#root {
  height: 100%;
}

.no-box-shadow {
  &:focus {
    box-shadow: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
