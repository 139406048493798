.staff-dialog.menu-dialog {
  figure.image-preview {
    margin-top: 1rem;
    width: 250px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media only screen and(max-width:400px) {
    figure.image-preview {
      width: 200px;
      height: 180px;
    }
    .booking-form__control.form-image {
      input[type="radio"]:first-child ~ label {
        margin-right: 10px !important;
      }
    }
  }
}
