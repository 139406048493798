#discount-dialog {
  .dialog {
    margin-top: 5rem;
    width: 700px;
    min-width: 700px;
  }
}

@media only screen and (max-width: 800px) {
  #discount-dialog {
    .dialog {
      margin-top: 5rem;
      width: 80%;
      min-width: 80%;
    }
  }
}

@media only screen and (max-width: 660px) {
  #discount-dialog {
    .dialog {
      margin-top: 5rem;
      width: 90%;
      min-width: 90%;
    }
  }
  .menu-dialog.discount-dialog .booking-form__control .form-time select {
    width: 66px;
  }
}

@media only screen and (max-width: 500px) {
  #discount-dialog {
    .dialog {
      margin-top: 5rem;
      width: 95%;
      min-width: 95%;
    }

    .date-time-wrapper {
      flex-direction: column;
      align-items: flex-start;

      > *:not(:nth-last-child(1)) {
        margin-bottom: 5px;
      }
    }
  }
}
