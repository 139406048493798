.menu {
  &__table {
    table > thead > tr {
      white-space: nowrap;

      th.col-username {
        width: 20%;
      }
      th.col-service {
        width: 35%;
      }
      th.col-activity {
        width: 20%;
      }
    }

    table > tbody > tr > td.buttons {
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 700px) {
  .menu__table {
    * {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 666px) {
  .menu__table {
    overflow-x: auto;

    table {
      width: 750px;
    }
  }
}
