$cold-turkey: #d0bcbc;
$grey: #7b7b7b;
$white: #fff;
$night-rider: #2f2f2f;
$unset: unset;
$bunting: #283046;
$medium-slate-blue: #7367f0;
$medium-slate-blue-70: rgba(115, 103, 240, 0.7);
$light-slate-blue-70: rgba(115, 103, 240, 0.7);

#check:checked ~ .navigation-panel {
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.8) 2.4px 0.4px 18.2px;
}

#check:checked ~ label {
  box-shadow: rgba(0, 0, 0, 0.8) 2.4px 0.4px 18.2px;
  left: 200px;
  z-index: 999999;
}

#check:checked ~ .navigation-panel__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.2);
}

label.navigation-panel__toggle-button {
  padding: 8px 8px;
  color: #fff;
  background: #283046;
  border-bottom-right-radius: 30%;
  font-size: 25px;
}

.navigation-panel {
  width: 260px;
  height: 100%;
  background: #283046;
  color: $white;
  position: fixed;
  transition: left 0.2s ease;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  $self: &;

  &__toggle-button {
    display: none;
  }

  &__shop-name {
    font-size: 1.3rem;
    padding: 1rem;
    line-height: 1.3;
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 1.2rem;
    color: #fff;

    &:after {
      content: "";
      width: 70%;
      height: 2px;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__calendar {
    padding: 3px;
    width: 100%;
    margin-top: auto;
  }

  .options {
    height: calc(100% - 52px);
    overflow-y: auto;
    padding: 0 8px;
    margin-bottom: 1rem;
    min-height: 220px;

    &__item {
      transition: all 0.1s ease;

      > span {
        display: block;
        padding-top: 1rem;
        padding-bottom: 0.7rem;
        padding-left: 1rem;
        color: $white;
        transition: all 0.2s ease;
        font-size: 1rem;
        letter-spacing: 1px;

        > p {
          color: #fff;
        }

        &:hover {
          padding-left: 1.2rem;
        }

        > .chevron-icon {
          position: absolute;
          right: 1rem;
          transition: all 0.3s ease;
        }
      }

      > ul {
        li {
          transition: all 0.1s ease;

          span {
            display: block;
            transition: all 0.2s ease;
            letter-spacing: 1px;
            color: $white;
            padding-top: 6px;
            padding-bottom: 6px;
            line-height: 18px;

            &:hover {
              padding-left: 4px;
              color: $white;
            }
          }

          i {
            padding-left: 1.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            font-size: 1rem;
          }

          &#{ $self }__staff {
            span {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.no-hover {
  &:hover {
    background: $unset !important;
  }
}

.rotate-right {
  transform: rotate(-90deg);
}

.rotate-down {
  transform: rotate(0deg);
}

.navigation-active {
  background: linear-gradient(118deg, #7f7f7f, #7f7f7f);
  border-radius: 5px;
  color: $white;
}
@media only screen and (max-width: 1000px) {
  .navigation-panel {
    width: 220px;
  }
}

@media only screen and (max-width: 900px) {
  .navigation-panel {
    left: -200px;
    z-index: 999;
    width: 200px;

    &__shop-name {
      font-size: 1.1rem;
    }

    &__toggle-button {
      display: block;
      left: 0;
      position: absolute;
      z-index: 999;
      color: #000;
      cursor: pointer;
      transition: left 0.2s ease;
    }
  }
}
